import { render, staticRenderFns } from "./FormListSubsection.vue?vue&type=template&id=514876f2&scoped=true&"
import script from "./FormListSubsection.vue?vue&type=script&lang=js&"
export * from "./FormListSubsection.vue?vue&type=script&lang=js&"
import style0 from "./FormListSubsection.vue?vue&type=style&index=0&id=514876f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "514876f2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('514876f2')) {
      api.createRecord('514876f2', component.options)
    } else {
      api.reload('514876f2', component.options)
    }
    module.hot.accept("./FormListSubsection.vue?vue&type=template&id=514876f2&scoped=true&", function () {
      api.rerender('514876f2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/Form/Subsection/FormListSubsection.vue"
export default component.exports